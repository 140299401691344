<template>
    <Modal v-if="showModal" size="play" @close="onClose()">
        <div slot="body" class="modal-content play-modal">
            <h2 class="heading heading--lg play-modal__heading">
                <OutsideIcon
                    width="19"
                    fill="#ffffff"
                    class="play-modal__heading--icon"
                />{{ label }}
            </h2>

            <div class="divider divider--20"></div>

            <h3 class="heading heading--md play-modal__subheading">
                Please choose a platform below to play this creation:
            </h3>

            <div class="platform-wrapper">
                <div class="platform platform-steam">
                    <a
                        :href="`steam://run/298610//-auto:mm_nav|target=game|workshopId=${id}`"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button
                            class="platform__button platform__button--steam button"
                            @click="onClose()"
                        >
                            <SteamIcon class="platform__button--icon-steam" />
                        </button>
                    </a>
                    <div class="platform-steam__link">
                        <OutsideIcon
                            width="18"
                            fill="#ffffff"
                            class="play-modal__heading--icon"
                        />
                        <span
                            >Download on
                            <a
                                href="https://store.steampowered.com/app/298610/Ylands/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Steam
                            </a>
                        </span>
                    </div>
                </div>
                <div class="platform platform-microsoft">
                    <a
                        :href="`ylands://-auto:mm_nav|target=game|workshopId=${id}`"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button
                            class="platform__button platform__button--microsoft button"
                            @click="onClose()"
                        >
                            <MicrosoftIcon
                                class="platform__button--icon-microsoft"
                            />
                        </button>
                    </a>
                    <div class="platform-microsoft__link">
                        <OutsideIcon
                            width="18"
                            fill="#ffffff"
                            class="play-modal__heading--icon"
                        />
                        <span
                            >Download on
                            <a
                                href="ms-windows-store://pdp/?ProductId=9P2TK0JNP5P7"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Windows Store
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from './Modal.vue';

import OutsideIcon from 'Sprites/outside.svg?inline';
import SteamIcon from 'Sprites/steam.svg?inline';
import MicrosoftIcon from 'Sprites/microsoft.svg?inline';

export default {
    components: {
        Modal,
        OutsideIcon,
        SteamIcon,
        MicrosoftIcon,
    },
    data() {
        return {
            showModal: false,
            label: '',
            assetId: '',
        };
    },
    methods: {
        onClose() {
            this.$emit('dialogClose');
        },
        init(label, id) {
            if (!label) {
                throw Error('label is required');
            }
            this.label = label;
            this.id = id;
            this.showModal = true;
            this.$once('dialogClose', () => {
                this.showModal = false;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.play-modal {
    text-align: center;
    color: #ffffff;
    .play-modal__heading {
        color: #ffffff;
        font-family: $istok-font;
        font-size: 22px;
        font-weight: 700;
        letter-spacing: 0.71px;

        &--icon {
            margin-right: 9px;
        }
    }
    .play-modal__subheading {
        color: #ffffff;
        font-family: $palanquin-font;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.26px;
        line-height: 25px;
    }
    .platform-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 65px;
        padding: 0 10px;

        & > div {
            margin: 0 10px;
        }

        .platform-microsoft__link,
        .platform-steam__link {
            font-family: $palanquin-font;
            margin-top: 20px;
            font-size: 18px;
            font-weight: 400;
            letter-spacing: 0.26px;
            line-height: 25px;
            width: 304px;

            a {
                color: $yellow-main;
                text-decoration: underline;
            }

            @media (max-width: 650px) {
                width: 182px;
            }
            @media (max-width: 420px) {
                width: 139px;
            }
        }

        .platform__button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 304px;
            height: 304px;

            @media (max-width: 650px) {
                width: 182px;
                height: 182px;
            }
            @media (max-width: 420px) {
                width: 139px;
                height: 139px;
            }

            &--steam {
                border-radius: 6px;
                background-color: #0a294a;
            }
            &--microsoft {
                border-radius: 6px;
                border: 1px solid #0a294a;
                background-color: #556f8b;
            }

            &--icon {
                &-steam {
                    width: 196px;
                    height: 65px;

                    @media (max-width: 650px) {
                        width: 118px;
                        height: 38px;
                    }

                    @media (max-width: 420px) {
                        width: 89px;
                        height: 29px;
                    }
                }

                &-microsoft {
                    width: 229px;
                    height: 50px;

                    @media (max-width: 650px) {
                        width: 143px;
                        height: 30px;
                    }
                    @media (max-width: 420px) {
                        width: 108px;
                        height: 23px;
                    }
                }
            }
        }
    }
}
</style>
